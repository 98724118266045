export function formatShippingAddress (shippingDetails, storeView) {
  return (shippingDetails) ? {
    region: shippingDetails.state,
    region_id: shippingDetails.region_id ? shippingDetails.region_id : 0,
    country_id: shippingDetails.country,
    street: [shippingDetails.streetAddress1, shippingDetails.streetAddress2],
    postcode: shippingDetails.zipCode,
    city: shippingDetails.city,
    region_code: shippingDetails.region_code ? shippingDetails.region_code : ''
  } : { country_id: storeView.tax.defaultCountry }
}
